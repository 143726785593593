const initState = {
  companyFetchLoading: false,
  companyFetchSuccess: false,
  companyFetchError: false,
  companyData: null,

  nlFetchLoading: false,
  nlFetchSuccess: false,
  nlFetchError: false,
  nlData: null,

  nlDataFetchLoading: false,
  nlDataFetchSuccess: false,
  nlDataFetchError: false,
  nlDataData: null,
  isDynamic: false,

  nlDataUpdateLoading: false,
  nlDataUpdateSuccess: false,
  nlDataUpdateError: false,
  nlDataUpdateMessage: null,

  nlRowColLoading: false,
  nlRowColSuccess: false,
  nlRowColError: false,
  nlRowColData: null,

  nlRowColUpdateLoading: false,
  nlRowColUpdateSuccess: false,
  nlRowColUpdateError: false,
  nlRowColUpdateMessage: null,

  fetchNLRowColCompanyLoading: false,
  fetchNLRowColCompanySuccess: false,
  fetchNLRowColCompanyError: false,
  fetchNLRowColCompanyData: null,
  fetchNLRowColCompanyMessage: null,

  nlRowColCompanyUpdateLoading: false,
  nlRowColCompanyUpdateSuccess: false,
  nlRowColCompanyUpdateError: false,
  nlRowColCompanyUpdateMessage: null,

  uploadNLPdfLoading: false,
  uploadNLPdfSuccess: false,
  uploadNLPdfError: false,
  uploadNLPdfMessage: null,

  nlRawDataFetchLoading: false,
  nlRawDataFetchSuccess: false,
  nlRawDataFetchError: false,
  nlRawData: null,
  nlRawDataFetchMsg: null,

  nlRawDataUpdateLoading: false,
  nlRawDataUpdateSuccess: false,
  nlRawDataUpdateError: false,
  nlRawDataUpdateMsg: null,

  deleteNLDataLoading: false,
  deleteNLDataSuccess: false,
  deleteNLDataError: false,
  deleteNLDataMsg: null,

  search: "",
};

const nlFixReducer = (state = initState, action) => {
  switch (action.type) {
    case "COMPANY_FETCH_LOADING":
      return {
        ...state,
        companyFetchLoading: true,
        companyFetchSuccess: false,
        companyFetchError: false,
        companyData: null,
      };
    case "COMPANY_FETCH_SUCCESS":
      return {
        ...state,
        companyFetchLoading: false,
        companyFetchSuccess: true,
        companyFetchError: false,
        companyData: action?.payload,
      };
    case "COMPANY_FETCH_ERROR":
      return {
        ...state,
        companyFetchLoading: false,
        companyFetchSuccess: false,
        companyFetchError: true,
        companyData: null,
      };
    case "NL_FETCH_LOADING":
      return {
        ...state,
        nlFetchLoading: true,
        nlFetchSuccess: false,
        nlFetchError: false,
        nlData: null,
      };
    case "NL_FETCH_SUCCESS":
      return {
        ...state,
        nlFetchLoading: false,
        nlFetchSuccess: true,
        nlFetchError: false,
        nlData: action?.payload,
      };
    case "NL_FETCH_ERROR":
      return {
        ...state,
        nlFetchLoading: false,
        nlFetchSuccess: false,
        nlFetchError: true,
        nlData: null,
      };
    case "NL_DATA_FETCH_LOADING":
      return {
        ...state,
        nlDataFetchLoading: true,
        nlDataFetchSuccess: false,
        nlDataFetchError: false,
        nlDataData: null,
        isDynamic: false,
      };
    case "NL_DATA_FETCH_SUCCESS":
      return {
        ...state,
        nlDataFetchLoading: false,
        nlDataFetchSuccess: true,
        nlDataFetchError: false,
        nlDataData: action?.payload,
        isDynamic: action?.isDynamic,
      };
    case "NL_DATA_FETCH_ERROR":
      return {
        ...state,
        nlDataFetchLoading: false,
        nlDataFetchSuccess: false,
        nlDataFetchError: true,
        nlDataData: null,
        isDynamic: false,
      };
    case "RESET_REDUCER":
      return {
        ...state,
        ...action.payload,
      };
    case "NL_DATA_UPDATE_LOADING":
      return {
        ...state,
        nlDataUpdateLoading: true,
        nlDataUpdateSuccess: false,
        nlDataUpdateError: false,
        nlDataUpdateMessage: null,
      };
    case "NL_DATA_UPDATE_SUCCESS":
      return {
        ...state,
        nlDataUpdateLoading: false,
        nlDataUpdateSuccess: true,
        nlDataUpdateError: false,
        nlDataUpdateMessage: action.message,
      };
    case "NL_DATA_UPDATE_ERROR":
      return {
        ...state,
        nlDataUpdateLoading: false,
        nlDataUpdateSuccess: false,
        nlDataUpdateError: true,
        nlDataUpdateMessage: action.message,
      };
    case "FETCH_NL_ROW_COL_LOADING":
      return {
        ...state,
        nlRowColLoading: true,
        nlRowColSuccess: false,
        nlRowColError: false,
        nlRowColData: null,
      };
    case "FETCH_NL_ROW_COL_SUCCESS":
      return {
        ...state,
        nlRowColLoading: false,
        nlRowColSuccess: true,
        nlRowColError: false,
        nlRowColData: action.payload,
      };
    case "FETCH_NL_ROW_COL_ERROR":
      return {
        ...state,
        nlRowColLoading: false,
        nlRowColSuccess: false,
        nlRowColError: true,
        nlRowColData: null,
      };
    case "NL_ROW_COLUMN_UPDATE_LOADING":
      return {
        ...state,
        nlRowColUpdateLoading: true,
        nlRowColUpdateSuccess: false,
        nlRowColUpdateError: false,
        nlRowColUpdateMessage: null,
      };
    case "NL_ROW_COLUMN_UPDATE_SUCCESS":
      return {
        ...state,
        nlRowColUpdateLoading: false,
        nlRowColUpdateSuccess: true,
        nlRowColUpdateError: false,
        nlRowColUpdateMessage: action.message,
      };
    case "NL_ROW_COLUMN_UPDATE_ERROR":
      return {
        ...state,
        nlRowColUpdateLoading: false,
        nlRowColUpdateSuccess: false,
        nlRowColUpdateError: true,
        nlRowColUpdateMessage: action.message,
      };
    case "FETCH_NL_ROW_COL_COMPANY_LOADING":
      return {
        ...state,
        fetchNLRowColCompanyLoading: true,
        fetchNLRowColCompanySuccess: false,
        fetchNLRowColCompanyError: false,
        fetchNLRowColCompanyData: null,
        fetchNLRowColCompanyMessage: null,
      };
    case "FETCH_NL_ROW_COL_COMPANY_SUCCESS":
      return {
        ...state,
        fetchNLRowColCompanyLoading: false,
        fetchNLRowColCompanySuccess: true,
        fetchNLRowColCompanyError: false,
        fetchNLRowColCompanyData: action.payload,
        fetchNLRowColCompanyMessage: action.message,
      };
    case "FETCH_NL_ROW_COL_COMPANY_ERROR":
      return {
        ...state,
        fetchNLRowColCompanyLoading: false,
        fetchNLRowColCompanySuccess: false,
        fetchNLRowColCompanyError: true,
        fetchNLRowColCompanyData: null,
        fetchNLRowColCompanyMessage: action.message,
      };
    case "NL_ROW_COLUMN_UPDATE_COMPANIES_LOADING":
      return {
        ...state,
        nlRowColCompanyUpdateLoading: true,
        nlRowColCompanyUpdateSuccess: false,
        nlRowColCompanyUpdateError: false,
        nlRowColCompanyUpdateMessage: null,
      };
    case "NL_ROW_COLUMN_UPDATE_COMPANIES_SUCCESS":
      return {
        ...state,
        nlRowColCompanyUpdateLoading: false,
        nlRowColCompanyUpdateSuccess: true,
        nlRowColCompanyUpdateError: false,
        nlRowColCompanyUpdateMessage: action.message,
      };
    case "NL_ROW_COLUMN_UPDATE_COMPANIES_ERROR":
      return {
        ...state,
        nlRowColCompanyUpdateLoading: false,
        nlRowColCompanyUpdateSuccess: false,
        nlRowColCompanyUpdateError: true,
        nlRowColCompanyUpdateMessage: action.message,
      };
    case "UPLOAD_NL_PDF_LOADING":
      return {
        ...state,
        uploadNLPdfLoading: true,
        uploadNLPdfSuccess: false,
        uploadNLPdfError: false,
        uploadNLPdfMessage: null,
      };
    case "UPLOAD_NL_PDF_SUCCESS":
      return {
        ...state,
        uploadNLPdfLoading: false,
        uploadNLPdfSuccess: true,
        uploadNLPdfError: false,
        uploadNLPdfMessage: action.message || "Nl Uploaded successfully.",
      };
    case "UPLOAD_NL_PDF_ERROR":
      return {
        ...state,
        uploadNLPdfLoading: false,
        uploadNLPdfSuccess: false,
        uploadNLPdfError: true,
        uploadNLPdfMessage: action.message || "Error to upload.",
      };
    case "NL_RAW_DATA_FETCH_LOADING":
      return {
        ...state,
        nlRawDataFetchLoading: true,
        nlRawDataFetchSuccess: false,
        nlRawDataFetchError: false,
        nlRawData: null,
        nlRawDataFetchMsg: null,
      };
    case "NL_RAW_DATA_FETCH_SUCCESS":
      return {
        ...state,
        nlRawDataFetchLoading: false,
        nlRawDataFetchSuccess: true,
        nlRawDataFetchError: false,
        nlRawData: action.payload,
        nlRawDataFetchMsg: action.message,
      };
    case "NL_RAW_DATA_FETCH_ERROR":
      return {
        ...state,
        nlRawDataFetchLoading: false,
        nlRawDataFetchSuccess: false,
        nlRawDataFetchError: true,
        nlRawData: null,
        nlRawDataFetchMsg: action.message,
      };
    case "NL_RAW_DATA_UPDATE_LOADING":
      return {
        ...state,
        nlRawDataUpdateLoading: true,
        nlRawDataUpdateSuccess: false,
        nlRawDataUpdateError: false,
        nlRawDataUpdateMsg: null,
      };
    case "NL_RAW_DATA_UPDATE_SUCCESS":
      return {
        ...state,
        nlRawDataUpdateLoading: false,
        nlRawDataUpdateSuccess: true,
        nlRawDataUpdateError: false,
        nlRawDataUpdateMsg: action?.message,
      };
    case "NL_RAW_DATA_UPDATE_ERROR":
      return {
        ...state,
        nlRawDataUpdateLoading: false,
        nlRawDataUpdateSuccess: false,
        nlRawDataUpdateError: true,
        nlRawDataUpdateMsg: action?.message,
      };
    case "DELETE_NL_DATA_LOADING":
      return {
        ...state,
        deleteNLDataLoading: true,
        deleteNLDataSuccess: false,
        deleteNLDataError: false,
        deleteNLDataMsg: null,
      };
    case "DELETE_NL_DATA_SUCCESS":
      return {
        ...state,
        deleteNLDataLoading: false,
        deleteNLDataSuccess: true,
        deleteNLDataError: false,
        deleteNLDataMsg: action?.message,
      };
    case "DELETE_NL_DATA_ERROR":
      return {
        ...state,
        deleteNLDataLoading: false,
        deleteNLDataSuccess: false,
        deleteNLDataError: true,
        deleteNLDataMsg: action?.message,
      };
    default:
      return initState;
  }
};

export default nlFixReducer;
