import React, { Component } from "react";
import * as d3 from "d3";

export class PieChartLegend extends Component {
  componentDidMount() {
    this.drawChart();
  }
  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const {
      id,
      data,
      width,
      height,
      dimensions,
      measures,
      onClick,
      noLegend = false,
      fontSize = 6,
      legendSize = 6,
      colorMap = null,
    } = this.props;

    // console.log("DATA", data);
    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d[measures[0].text]);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(Math.min(width, height) / 4 - 1);

    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d[dimensions[0].text]))
      .range(
        d3
          .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
          .reverse(),
      );

    d3.select("#" + id)
      .select("svg")
      .remove();

    const arcs = pie(data);
    const svg = !noLegend
      ? d3
          .select("#" + id)
          .append("svg")
          .attr("viewBox", [-height / 4 - 20, -width / 4, width, height / 2])
      : d3
          .select("#" + id)
          .append("svg")
          .attr("viewBox", [-height / 2, -width / 4, width, height / 2]);

    svg
      .append("g")
      .attr("stroke", "white")
      .selectAll("path")
      .data(arcs)
      .join("path")
      .attr("fill", (d) =>
        colorMap
          ? colorMap[d.data[dimensions[0].text]]
          : color(d.data[dimensions[0].text]),
      )
      .attr("d", arc)
      .append("title")
      .text(
        (d) =>
          `${d.data[dimensions[0].text]}: ${d.data[
            measures[0].text
          ].toLocaleString()}`,
      );

    if (!noLegend) {
      var legend = svg
        .append("g")
        .attr("transform", "translate(" + -width / 7 + "," + -width / 8 + ")")
        .attr("text-anchor", "end")
        .attr("font-family", "sans-serif")
        .attr("font-size", fontSize)
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d, i) {
          return "translate(0," + i * 10 + ")";
        });

      legend
        .append("rect")
        .attr("x", width - 50)
        .attr("y", legendSize)
        .attr("width", legendSize)
        .attr("height", legendSize)
        .attr("fill", (d) => color(d[dimensions[0].text]));

      legend
        .append("text")
        .attr("x", width - 60)
        .attr("y", legendSize + legendSize / 2)
        .attr("dy", "0.32em")
        .text(function (d) {
          return d[dimensions[0].text];
        });
    }
  }

  render() {
    return <div id={this.props.id} style={{ margin: 0 }}></div>;
  }
}

export default PieChartLegend;
