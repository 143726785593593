import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";
import { orderFilters } from "../../../utils/tableHelper";
import BarChartGeneric from "../../layout/BarChartGeneric";
import moment from "moment";
import LineChartGeneric from "../../layout/LineChartGeneric";
import { useSelector } from "react-redux";
import * as d3 from "d3";

export default function AgingDashboard({ label, data }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);
  const [myCompany, setMyCompany] = useState(null);
  const [secondaries, setSecondaries] = useState(null);
  const [allComs, setAllComs] = useState([]);
  const [lineDataNop, setLineDataNop] = useState([]);
  const [barDataNop, setBarReportDataNop] = useState([]);
  const [lineDataAmount, setLineDataAmount] = useState([]);
  const [barDataAmount, setBarReportDataAmount] = useState([]);
  const [selLob, setSelLob] = useState(null);
  const [lobs, setLobs] = useState([]);
  const [type, setType] = useState("nop");

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com = userCompany?.find(
        (com) => com?.detail?.sector_id === insSector?.id,
      );
      let secs = com?.secondaries;
      setMyCompany(com?.detail);
      setSecondaries(com?.secondaries);
      let temp_lobs = [];
      for (let i = 0; i < data?.nop?.length; i++) {
        if (!temp_lobs.includes(data?.nop[i]["LOB"])) {
          temp_lobs.push(data?.nop[i]["LOB"]);
        }
      }
      temp_lobs = orderFilters(temp_lobs);
      setLobs(temp_lobs);
      setSelLob(temp_lobs[0]);
      setData(com?.detail, secs, temp_lobs[0]);
    }
  }, [data]);

  const setData = (com, secs, lob) => {
    let coms = [...secs];
    coms.push(com);
    coms = coms.map((com) => {
      return com.name;
    });
    setAllComs(coms);

    let tempNop = data?.nop?.filter((row) => row.LOB === lob);
    let barNop = tempNop?.map((d) => {
      return { company: d.Company, overall: d.Overall };
    });
    barNop = barNop?.filter((data) => coms.includes(data.company));
    let lineNOp = makeTimeWise(tempNop, coms);
    setBarReportDataNop(barNop);
    setLineDataNop(lineNOp);
    let tempPrem = data?.amount?.filter((row) => row.LOB === lob);
    let barAm = tempPrem?.map((d) => {
      return { company: d.Company, overall: d.Overall };
    });
    barAm = barAm?.filter((data) => coms.includes(data.company));
    let lineAmount = makeTimeWise(tempPrem, coms);
    setBarReportDataAmount(barAm);
    setLineDataAmount(lineAmount);
  };

  const makeTimeWise = (arrayData, coms) => {
    let newData = [];
    let segKeys = Object.keys(arrayData[0])?.filter((key) => key.includes("%"));
    for (let i = 0; i < segKeys.length; i++) {
      // if (segKeys[i] === "<1M (%)" || segKeys[i] === ">1M & <=3M (%)") {
      //   continue;
      // }
      let key = segKeys[i].replaceAll("(%)", "").trim();
      key = key.replaceAll("<", "").trim();
      key = key.replaceAll(">", "").trim();
      key = key.replaceAll("=", "").trim();
      // key = key.replaceAll("M", "").trim();
      // key = key.replaceAll("Y", "").trim();
      key = key.replaceAll("&", "-").trim();
      let newOb = { key, date: key };
      for (let j = 0; j < coms.length; j++) {
        let comData = arrayData.find((d) => d.Company === coms[j]);
        newOb[coms[j]] = comData[segKeys[i]];
      }
      newData.push(newOb);
    }
    console.log("NEW DATA", newData);
    return newData;
  };

  const handleLobChange = (lob) => {
    // let temp_data = data[type]?.filter((row) => row.LOB === lob);
    // setReportData(temp_data);
    setSelLob(lob);
    setData(myCompany, secondaries, lob);
  };

  // console.log("COLS", cols);
  console.log("LOBS", lobs);
  console.log("REPORT DATA", lineDataNop, lineDataAmount);
  console.log("REPORT DATA", data);

  const BAR_CHART_HEIGHT = 200;
  const BAR_CHART_WIDTH = 350;
  const LINE_CHART_HEIGHT = 230;
  const LINE_CHART_WIDTH = 780;

  return (
    <>
      <div className="row" style={{ marginBottom: 0 }}>
        <div className="col s12">
          <div className="stat-row">
            {lobs?.map((lob) => (
              <div
                className="btn btn-small"
                onClick={() => {
                  handleLobChange(lob);
                }}
                style={
                  lob === selLob
                    ? {
                        marginRight: 8,
                        marginBottom: 8,
                        backgroundColor: "#124265",
                      }
                    : {
                        marginRight: 8,
                        marginBottom: 8,
                        backgroundColor: "#dcdce2",
                        color: "black",
                      }
                }
              >
                {lob}
              </div>
            ))}
          </div>
          {/* <div className="col s2">
            <label>
              <input
                name="group1"
                type="radio"
                checked={type === "nop"}
                onChange={() => {
                  handleTypeChange("nop");
                }}
              />
              <span>NOP</span>
            </label>
            <label>
              <input
                name="group1"
                type="radio"
                checked={type === "amount"}
                onChange={() => {
                  handleTypeChange("amount");
                }}
              />
              <span>Premium</span>
            </label>
          </div> */}
        </div>
        <div className="col s12">
          <div className="col s4">
            <div>
              <div style={{ fontSize: 18 }}>
                <strong>Policy Wise</strong>
              </div>
              <BarChartGeneric
                id={"nop-overall"}
                data={barDataNop}
                dimensions={[{ text: "company" }]}
                measures={[{ text: "overall" }]}
                height={BAR_CHART_HEIGHT}
                width={BAR_CHART_WIDTH}
                hideLegend={false}
                margins={{ left: 100, bottom: 10 }}
                fontSize={12}
                boldFont={true}
                noLabelWrapY={true}
                noXLabel={true}
                labelInBar={false}
                barFontSize={10}
              />
            </div>
            <div>
              <div style={{ fontSize: 18 }}>
                <strong>Premium Wise</strong>
              </div>
              <BarChartGeneric
                id={"amount-overall"}
                data={barDataAmount}
                dimensions={[{ text: "company" }]}
                measures={[{ text: "overall" }]}
                height={BAR_CHART_HEIGHT}
                width={BAR_CHART_WIDTH}
                hideLegend={false}
                margins={{ left: 100, bottom: 10 }}
                fontSize={12}
                boldFont={true}
                noLabelWrapY={true}
                noXLabel={true}
                labelInBar={false}
                barFontSize={10}
              />
            </div>
            <p className="notes-text">
              ** All segment wise figures are in percentage of total for the
              segment
            </p>
          </div>
          <div className="col s8">
            <div>
              <LineChartGeneric
                id="trend-nop"
                data={lineDataNop}
                dimensions={[{ text: "date" }]}
                measures={allComs?.map((com) => {
                  return { text: com };
                })}
                can_width={LINE_CHART_WIDTH}
                can_height={LINE_CHART_HEIGHT}
                tooltip={false}
                labelInBar={true}
                margins={{ bottom: 20, left: 20, right: 10 }}
                legend={false}
                rotateXTick={true}
              />
            </div>
            <div>
              <LineChartGeneric
                id="trend-amount"
                data={lineDataAmount}
                dimensions={[{ text: "date" }]}
                measures={allComs?.map((com) => {
                  return { text: com };
                })}
                can_width={LINE_CHART_WIDTH}
                can_height={LINE_CHART_HEIGHT}
                tooltip={false}
                labelInBar={true}
                margins={{ bottom: 20, left: 20, right: 10 }}
                legend={false}
                rotateXTick={true}
              />
            </div>
            <div>
              {allComs?.map((com, i) => (
                <div
                  className="col s2"
                  title={com}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 12,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      minHeight: 10,
                      minWidth: 10,
                      height: 10,
                      width: 10,
                      marginRight: 10,
                      backgroundColor: d3.schemeCategory10[i],
                    }}
                  ></div>
                  <div>{com.length > 15 ? com.slice(0, 15) + "..." : com}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
