import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BarStackCombination from "./commonDashboards/BarStackCombination";
import { useSelector } from "react-redux";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../../config/constants";

export default function SegmentCommission({ label, data, type }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [reportData, setReportData] = useState([]);
  const [reportDataTwo, setReportDataTwo] = useState([]);
  const [reportDataComGdp, setReportDataComGdp] = useState([]);
  const [gdpFlag, setGdpFlag] = useState(false);

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com =
        !type || type === NL_TYPE_GIC
          ? userCompany?.find(
              (com) =>
                com?.detail?.sector_id === insSector?.id &&
                com?.detail?.insuranceType !== NL_TYPE_LIFE,
            )
          : userCompany?.find(
              (com) =>
                com?.detail?.sector_id === insSector?.id &&
                com?.detail?.insuranceType === NL_TYPE_LIFE,
            );
      let secs = com?.secondaries;
      let coms = [...secs];
      coms.push(com?.detail);
      console.log("COMS", coms);
      setReportData(makeFlatData(data, coms));
      setReportDataTwo(getBarChartData(data, coms, "Gross Commission"));
      setReportDataComGdp(getBarChartData(data, coms, "commission_gdp"));
    }
  }, [data]);

  const getBarChartData = (data, coms, field) => {
    let newData = [];
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          [field]: d[field],
        });
      }
    });
    newData = newData?.sort((a, b) => (a[field] < b[field] ? 1 : -1));

    return newData;
  };

  const makeFlatData = (arrayData, coms) => {
    let newData = [];
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let segKeys = Object.keys(d)?.filter((key) => key.includes("%"));
      for (let j = 0; j < segKeys.length; j++) {
        let comFound = coms.find((com) => com.name == d.Company);
        if (comFound)
          newData.push({
            company: d.Company,
            segment: segKeys[j].replace("(%)", "").trim(),
            data: d[segKeys[j]],
          });
      }
    }
    return newData;
  };

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  console.log("REPORT DATA", reportData);
  const BAR_CHART_HEIGHT = type === NL_TYPE_LIFE ? 250 : 530;
  const BAR_CHART_WIDTH = 400;
  return (
    <BarStackCombination
      barOneDetails={{
        id: "nic",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "Gross Commission" }],
        label: type === NL_TYPE_LIFE ? "Net Commission" : "Gross Commission",
        data: reportDataTwo,
      }}
      barTwoDetails={
        type === NL_TYPE_LIFE
          ? {
              id: "comm-gdp",
              height: BAR_CHART_HEIGHT,
              width: BAR_CHART_WIDTH,
              dimensions: [{ text: "company" }],
              measures: [{ text: "commission_gdp" }],
              label: "Commission to GDP",
              data: reportDataComGdp,
            }
          : null
      }
      stackBarDetails={{
        id: "seg-rev",
        width: 800,
        height: type === NL_TYPE_LIFE ? 500 : 450,
        dimensions: [{ text: "company" }],
        measures: [{ text: "data" }],
        group: "segment",
        label:
          type === NL_TYPE_LIFE
            ? "Contribution of products on comm"
            : "Contribution of segments",
        data: gdpFlag
          ? reportData
              ?.filter((d) => d.segment?.includes("_per_gdp"))
              ?.map((d) => {
                return { ...d, segment: d.segment.replace("_per_gdp", "") };
              })
          : reportData?.filter((d) => !d.segment?.includes("_per_gdp")),
        gic: false,
      }}
      life={type === NL_TYPE_LIFE}
      handleSwitch={(e) => {
        setGdpFlag(!gdpFlag);
      }}
      gdpFlag={gdpFlag}
      switchActive={type === NL_TYPE_LIFE}
    />
  );
}
