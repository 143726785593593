export const refreshInterval = 15 * 60 * 1000; // 15 minutes
export const GENERATE_EXCEL_SETTINGS = "generate_excel_server";

const BLUE = "#a5cdf0";
const ORANGE = "#feeea3";
const GREEN = "#89cfa5";
const RED = "#ff8c00";
const LIGHT_PURPLE = "#33ccff";
const LIGHT_BROWN = "#ff6699";
const PINK = "#ff9900";
const LIGHT_GRAY = "#00cc99";
const FADE_GREEN = "#ff33cc";
const CYAN = "#669999";
const DARK_BLUE = "#1f77b4";
const SAP_GREEN = "#438869";
const YELLOW = "#b582cf";
const CREAM = "#ffccff";
const PURPLE = "#ffff66";
const DEEP_PINK = "#ccffcc";
const LIGHT_GREEN = "#33cc33";
const LIGHT_BLUE = "#ffA07a";

export const graphColors = [
  BLUE,
  ORANGE,
  GREEN,
  RED,
  LIGHT_PURPLE,
  LIGHT_BROWN,
  PINK,
  LIGHT_GRAY,
  FADE_GREEN,
  CYAN,
  YELLOW,
  CREAM,
  PURPLE,
  DARK_BLUE,
  DEEP_PINK,
  SAP_GREEN,
  LIGHT_GREEN,
  LIGHT_BLUE,
];

// export const specialColorMap = {
//   Fire: RED,
//   "Marine Cargo": SAP_GREEN,
//   "Marine Hull": FADE_GREEN,
//   "Motor OD": BLUE,
//   "Motor TP": DARK_BLUE,
//   Health: ORANGE,
//   "Personal Accident": LIGHT_GREEN,
//   "P.A.": LIGHT_GREEN,
//   Travel: LIGHT_PURPLE,
//   "Workmen's Comp & Employer's Liability": DEEP_PINK,
//   "Public/ Product Liability": CREAM,
//   Liability: CREAM,
//   Engineering: PURPLE,
//   Aviation: LIGHT_BLUE,
//   Crop: GREEN,
//   Credit: CYAN,
//   "Other Misc.": YELLOW,
//   "All Other Misc (Crop Insurance + Credit Guarantee+All other misc)": YELLOW,
// };

export const specialColorMap = {
  Fire: "rgb(66, 136, 181)",
  "Marine Cargo": "rgb(84, 170, 175)",
  "Marine Hull": "rgb(119, 198, 166)",
  "Motor OD": "rgb(159, 217, 163)",
  "Motor TP": "rgb(197, 232, 159)",
  Health: "rgb(227, 244, 162)",
  "Personal Accident": "rgb(246, 250, 174)",
  "P.A.": "rgb(246, 250, 174)",
  Travel: "rgb(253, 243, 170)",
  "Workmen's Comp & Employer's Liability": "rgb(254, 224, 144)",
  "Public/ Product Liability": "rgb(253, 196, 117)",
  Liability: "rgb(253, 196, 117)",
  Engineering: "rgb(251, 162, 94)",
  Aviation: "rgb(244, 125, 77)",
  Crop: "rgb(231, 91, 73)",
  Credit: "rgb(209, 60, 75)",
  Speciality: "rgb(242, 90, 105)",
  "Other Misc.": "rgb(66, 136, 181)",
  "All Other Misc (Crop Insurance + Credit Guarantee+All other misc)":
    "rgb(66, 136, 181)",
};

export const specialColorMapLife = {
  NonPAR: "rgb(66, 136, 181)",
  ULIP: "rgb(84, 170, 175)",
  PAR: "rgb(159, 217, 163)",
};

export const specialColorMapLifeGeo = {
  "Num Policies  (Rural)": "rgb(66, 136, 181)",
  "Premium (Rural)": "rgb(84, 170, 175)",
  "Num Policies (Urban)": "rgb(119, 198, 166)",
  "Premium (Urban)": "rgb(159, 217, 163)",
  "Num Policies (Total)": "rgb(197, 232, 159)",
  "Premium (Total)": "rgb(227, 244, 162)",
  "Renewal Premium": "rgb(251, 162, 94)",
  "Total Premium (New Business and Renewal)": "rgb(66, 136, 181)",
};

export const gic_map = {
  Fire: true,
  "Marine Cargo": true,
  "Marine Hull": true,
  "Motor OD": true,
  "Motor TP": true,
  Health: true,
  "Personal Accident": false,
  "P.A.": true,
  Travel: true,
  "Workmen's Comp & Employer's Liability": false,
  "Public/ Product Liability": false,
  Liability: true,
  Engineering: true,
  Aviation: true,
  Crop: false,
  Credit: false,
  "Other Misc.": false,
  Speciality: false,
  "All Other Misc (Crop Insurance + Credit Guarantee+All other misc)": true,
};

export const norm_map = {
  Fire: true,
  "Marine Cargo": true,
  "Marine Hull": true,
  "Motor OD": true,
  "Motor TP": true,
  Health: true,
  "Personal Accident": true,
  "P.A.": false,
  Travel: true,
  "Workmen's Comp & Employer's Liability": true,
  "Public/ Product Liability": true,
  Liability: false,
  Engineering: true,
  Aviation: true,
  Crop: true,
  Credit: true,
  "Other Misc.": true,
  Speciality: true,
  "All Other Misc (Crop Insurance + Credit Guarantee+All other misc)": false,
};

export const seg_orders = {
  "Motor OD": 1,
  "Motor TP": 2,
  Health: 3,
  "P.A.": 4,
  "Personal Accident": 4,
  Fire: 5,
  "Marine Cargo": 6,
  "Marine Hull": 7,
  Engineering: 8,
  Aviation: 9,
  Travel: 10,
  Crop: 11,
  "Workmen's Comp & Employer's Liability": 12,
  "Public/ Product Liability": 13,
  Liability: 14,
  Credit: 15,
  Speciality: 16,
  "Other Misc.": 17,
  "All Other Misc (Crop Insurance + Credit Guarantee+All other misc)": 17,
};

export const NL_TYPE_GIC = "gic";
export const NL_TYPE_LIFE = "life";

export const allTools = [
  {
    name: "dataInfo",
    text: "Data Info",
    toolId: "statistics",
    color: "#bfbf0d",
    image: "bar-chart-white.png",
    noModal: true,
  },
  {
    name: "monthlyDashboard",
    text: "Monthly Dashboard",
    toolId: "nl-monthly",
    color: "#5d1b8f",
    image: "line-bar.jpg",
    modal: "dashboard-modal",
    noModal: true,
  },
  {
    name: "quarterlyDashboard",
    text: "Quarterly Dashboard",
    toolId: "nl-reports",
    color: "#5d1b8f",
    image: "bar-chart-white.png",
    modal: "dashboard-modal",
    noModal: true,
  },
  {
    name: "quarterlyDashboardLife",
    text: "Quarterly Dashboard Life",
    toolId: "nl-reports-life",
    color: "#5d1b8f",
    image: "bar-chart-white.png",
    modal: "dashboard-modal",
    noModal: true,
  },
  {
    name: "rawData",
    text: "Raw Data",
    toolId: "nl-download",
    color: "#5d1b8f",
    image: "download.png",
    modal: "dashboard-modal",
  },

  {
    name: "concall",
    text: "Concall",
    toolId: "concall",
    color: "#5d1b8f",
    image: "call-1.jpg",
  },
  {
    name: "pdTool",
    text: "TbDS",
    toolId: "distress predictor",
    color: "#2d49a6",
    image: "line-bar.jpg",
  },
  {
    name: "nis",
    text: "News Impact",
    toolId: "news impact",
    color: "#2d49a6",
    image: "report.png",
  },
  {
    name: "auditMatter",
    toolId: "audit-matter",
    text: "Audit Matters",
    color: "#2d49a6",
    image: "budget.png",
  },
  {
    name: "auditMonitor",
    toolId: "audit-monitor",
    text: "Audit Monitor",
    color: "#2d49a6",
    image: "account-1.jpg",
  },
  {
    name: "sm",
    text: "Sector Meter",
    toolId: "sector meter",
    color: "#3f8c23",
    image: "speedometer.png",
  },
  {
    name: "stockPulse",
    text: "Stock Pulse",
    toolId: "stock pulse",
    color: "#bf750d",
    image: "candlestick.png",
  },

  {
    name: "announcements",
    text: "Corporate Announcements",
    toolId: "announcement",
    color: "#bfbf0d",
    image: "order-1.jpg",
  },
  {
    name: "accPol",
    text: "Accounting Policy Alert",
    toolId: "acc-policy",
    color: "#bf750d",
    image: "policy.png",
  },
  {
    name: "broadFin",
    text: "Broad Financial",
    toolId: "broad-finance",
    color: "#bfbf0d",
    image: "analysis.png",
  },

  {
    name: "addCompany",
    text: "Add Company",
    toolId: "add-company",
    color: "#bfbf0d",
    image: "configure.png",
    modal: "add-company-modal",
  },

  {
    name: "reportDownload",
    text: "Download Report",
    toolId: "download-report",
    color: "#bfbf0d",
    image: "download.png",
    modal: "download-report-modal",
  },
  {
    name: "dataSummary",
    text: "Data Summary",
    toolId: "statistics",
    color: "#bfbf0d",
    image: "bar-chart-white.png",
    hide: true,
  },
  {
    name: "rawDataLife",
    text: "Raw Data Life",
    toolId: "nl-download-life",
    color: "#5d1b8f",
    image: "download.png",
    modal: "dashboard-modal",
  },
];
// motor OD, motor tp, health, pa, fire, marine cargo, marine Hull, eng, avia, travel, crop, work, public, credit, other

export const ENTITY_STATUS = {
  ACTIVE: "active",
};
