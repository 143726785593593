import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";
import { specialColorMap } from "../../../config/constants";
import { useSelector } from "react-redux";
import BarChartGeneric from "../../layout/BarChartGeneric";
import BarChartVertical from "../../layout/BarChartGenericVertical";
import PieChartLegend from "../../layout/PieChartLegend";
import Legends from "./laylout/Legends";
import StackBar from "./laylout/StackBar";
import BarStackCombination from "./commonDashboards/BarStackCombination";

export default function RevenuePerf({ label, data }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [myCompany, setMyCompany] = useState(null);
  const [secondaries, setSecondaries] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportDataTwo, setReportDataTwo] = useState([]);

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com = userCompany?.find(
        (com) => com?.detail?.sector_id === insSector?.id,
      );
      let secs = com?.secondaries;
      setMyCompany(com?.detail);
      setSecondaries(com?.secondaries);
      // setReportData(makeSegWise(data));
      let coms = [...secs];
      coms.push(com?.detail);
      setReportData(makeFlatData(data, coms));
      setReportDataTwo(getBarChartData(data, coms));
    }
  }, [data]);

  const getBarChartData = (data, coms) => {
    let newData = [];
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          pat: d.PAT,
          gdp: d.GDP,
        });
      }
    });

    return newData;
  };

  const makeSegWise = (arrayData) => {
    let newData = {};
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let newArr = [];
      let segKeys = Object.keys(d)?.filter((key) => key.includes("%"));
      for (let j = 0; j < segKeys.length; j++) {
        newArr.push({
          segment: segKeys[j].replace("(%)", "").trim(),
          data: d[segKeys[j]],
        });
      }
      newData[d.Company] = newArr;
    }
    return newData;
  };

  const makeFlatData = (arrayData, coms) => {
    let newData = [];
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let segKeys = Object.keys(d)?.filter((key) => key.includes("%"));
      for (let j = 0; j < segKeys.length; j++) {
        let comFound = coms.find((com) => com.name == d.Company);
        if (comFound)
          newData.push({
            company: d.Company,
            segment: segKeys[j].replace("(%)", "").trim(),
            data: d[segKeys[j]],
          });
      }
    }
    return newData;
  };

  // console.log("COLS", cols);
  console.log("LOBS", secondaries);
  console.log("REPORT DATA", reportData);

  const BAR_CHART_HEIGHT = 255;
  const BAR_CHART_WIDTH = 350;
  return (
    <BarStackCombination
      barOneDetails={{
        id: "gdp",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "gdp" }],
        label: "Gross Direct Premium",
        data: reportDataTwo?.sort((a, b) => (a.gdp < b.gdp ? 1 : -1)),
      }}
      barTwoDetails={{
        id: "pat",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "pat" }],
        label: "Profit After Tax",
        data: reportDataTwo?.sort((a, b) => (a.pat < b.pat ? 1 : -1)),
      }}
      stackBarDetails={{
        id: "seg-rev",
        height: 460,
        width: 800,
        dimensions: [{ text: "company" }],
        measures: [{ text: "data" }],
        group: "segment",
        label: "Contribution of segments",
        data: reportData,
        gic: false,
      }}
    />
  );
}
