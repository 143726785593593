import React, { Component } from "react";
import * as d3 from "d3";
import { graphColors } from "../../../../config/constants";

class StackBarHorizontal extends Component {
  state = {
    dataGroups: [],
    groupSet: false,
    groupColors: {},
  };
  componentDidMount() {
    this.drawChart();
    if (!this.state.groupSet && this.props.data?.length > 0) {
      this.setState({
        dataGroups: this.getGroups(),
        groupColors: this.getGroupColors(),
        groupSet: true,
      });
    }
  }

  componentDidUpdate() {
    this.drawChart();
    if (!this.state.groupSet && this.props.data?.length > 0) {
      this.setState({
        dataGroups: this.getGroups(),
        groupColors: this.getGroupColors(),
        groupSet: true,
      });
    }
  }

  getYDomain = (dataset) => {
    if (!dataset || (dataset && dataset.length < 1)) {
      return [];
    }

    let domainArr = [];
    for (var i = 0; i < dataset.length; i++) {
      if (!domainArr.includes(dataset[i].y)) {
        domainArr.push(dataset[i].y);
      }
    }
    // console.log("DOMAIN", domainArr, dataset);
    return domainArr;
  };

  getGroups = () => {
    let groups = [];
    let group = this.props.group;
    for (var i = 0; i < this.props.data.length; i++) {
      if (!groups.includes(this.props.data[i][group])) {
        groups.push(this.props.data[i][group]);
      }
    }
    return groups;
  };

  groupData = () => {
    let dim = this.props.dimensions[0].text;
    let mea = this.props.measures[0].text;
    let group = this.props.group;
    let groups = this.getGroups();
    // console.log("Groups", groups);
    let newData = [];
    for (var i = 0; i < groups.length; i++) {
      let dataFilterd = this.props.data.filter((d) => d[group] === groups[i]);
      dataFilterd = dataFilterd.map((d) => {
        return { x: d[mea], y: d[dim], group: groups[i] };
      });
      newData.push(dataFilterd);
    }

    // console.log("Data:", newData);
    newData = newData[0].map((_, colIndex) =>
      newData.map((row) => row[colIndex]),
    );
    let newDataSorted = newData.map((groupData) => {
      return groupData?.sort((a, b) => (a.x < b.x ? 1 : -1));
    });

    // console.log("Data 2:", newDataSorted);

    return newDataSorted;
  };

  getGroupColors = () => {
    let cols = graphColors;
    let groups = this.getGroups();
    let colorMap = {};
    for (let i = 0; i < groups.length; i++) {
      colorMap[groups[i]] = cols[i];
    }
    return colorMap;
  };

  drawChart() {
    const {
      can_width,
      can_height,
      data,
      dimensions,
      measures,
      filters,
      group,
      hideLegend = false,
      id,
      margins,
      noY = false,
      noYTick = false,
      charLength = 70,
      noX,
      noXTick,
      legendInRow = 4,
      colorMap = null,
    } = this.props;

    var margin = {
      top: margins?.top || 20,
      right: margins?.right || 10,
      bottom: margins?.bottom || 65,
      left: margins?.left || 30,
    };
    if (!data) return;
    if (data.length == 0) return;
    var width = can_width - margin.left - margin.right,
      height = can_height - margin.top - margin.bottom;

    var wrap = function () {
      var maxLen = charLength;
      var self = d3.select(this),
        textLength = self.node().getComputedTextLength(),
        text = self.text();
      while (textLength > maxLen && text.length > 0) {
        text = text.slice(0, -1);
        self.text(text + "...");
        textLength = self.node().getComputedTextLength();
      }
    };

    d3.select("#" + id)
      .select("svg")
      .remove();
    // console.log(
    //   "GETtiNG DRAWN",
    //   width + margin.left + margin.right,
    //   height + margin.top + margin.bottom
    // );
    var svg = d3
      .select("#" + id)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    /* Data in strings like it would be if imported from a csv */

    // Transpose the data into layers
    var m =
      data && data.length > 0
        ? data.map((d) => {
            return { y: d[dimensions[0].text], x: +d[measures[0].text] };
          })
        : [];
    // console.log("M", m);
    var dataset = m;
    //console.log("dataset", dataset);

    // Set x, y and colors
    var y = d3
      .scaleBand()
      .domain(this.getYDomain(dataset))
      .rangeRound([0, height])
      .padding(0.02);

    let xMax = d3.max(this.groupData(), function (gr) {
      return d3.sum(gr, function (d) {
        return d.x;
      });
    });

    var x = d3.scaleLinear().domain([0, xMax]).range([0, width]);

    var colors = this.state.groupColors;

    // Define and draw axes
    var yAxis = null;

    if (!noY) {
      if (!noYTick) {
        yAxis = d3.axisLeft().scale(y);
      } else {
        yAxis = d3.axisLeft().ticks(0).tickSize(0).scale(y);
      }
    }

    var xAxis = null;
    if (!noX) {
      if (!noXTick) {
        xAxis = d3.axisBottom().scale(x);
      } else {
        xAxis = d3.axisBottom().ticks(0).tickSize(0).scale(x);
      }
    }

    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .selectAll("text")
      .attr("dy", 15)
      .attr("transform", "rotate(90)")
      .style("text-anchor", "middle");

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    // Create groups for each series, rects for each segment
    var groups = svg
      .selectAll("g.cost")
      .data(this.groupData(dataset))
      .enter()
      .append("g")
      .attr("class", "cost");

    var rect = groups
      .selectAll("rect")
      .data(function (d) {
        // console.log("d", d);
        let temp = d?.sort((a, b) => (a.x < b.x ? 1 : -1));
        let fullSum = d3.sum(temp, function (t) {
          return t.x;
        });
        let sum = 0;
        for (var i = 0; i < temp.length; i++) {
          temp[i] = { ...temp[i], xPrev: sum, total: fullSum };
          sum += temp[i].x;
        }
        return temp;
      })
      .enter()
      .append("rect")
      .attr("x", function (m) {
        return x(m.total - m.xPrev - m.x);
      })
      .attr("y", function (m) {
        return y(m.y);
      })
      .attr("width", function (m) {
        return x(m.x);
      })
      .attr("height", Math.ceil(height / this.getYDomain(dataset)?.length) - 10)
      .style("fill", function (d, i) {
        if (colorMap) {
          return colorMap[d.group];
        } else {
          return colors[d.group];
        }
      })
      .append("title")
      .text((m) => {
        return m?.x;
      });
    // .on("mouseover", function() { tooltip.style("display", null); })
    // .on("mouseout", function() { tooltip.style("display", "none"); })
    // .on("mousemove", function(m) {
    //   var xPosition = d3.mouse(this)[0] - 15;
    //   var yPosition = d3.mouse(this)[1] - 25;
    //   tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
    //   tooltip.select("text").text(m.y);
    // });

    // Draw legend
    if (!hideLegend) {
      var legend = svg
        .selectAll(".legend")
        .data(this.getGroups())
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d, i) {
          return (
            "translate(" +
            String((i % legendInRow) * 90) +
            "," +
            String(Number(height) + 30 + Math.floor(i / legendInRow) * 12) +
            ")"
          );
        });
      legend
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", function (d, i) {
          if (colorMap) {
            return colorMap[d];
          } else {
            return colors[d];
          }
        });

      legend
        .append("text")
        .attr("x", 12)
        .attr("y", 5)
        .style("font-size", 10)
        .style("font-weight", "bold")
        .attr("dy", ".35em")
        .style("text-anchor", "start")
        .text(function (d, i) {
          return d;
        })
        .each(wrap);
    }

    // Prep the tooltip bits, initial display is hidden
    // var tooltip = svg.append("g")
    //   .attr("class", "tooltip")
    //   .style("display", "none");

    // tooltip.append("rect")
    //   .attr("width", 30)
    //   .attr("height", 20)
    //   .attr("fill", "white")
    //   .style("opacity", 0.5);

    // tooltip.append("text")
    //   .attr("x", 15)
    //   .attr("dy", "1.2em")
    //   .style("text-anchor", "middle")
    //   .attr("font-size", "12px")
    //   .attr("font-weight", "bold");
  }

  render() {
    return <div id={this.props.id} style={{ margin: 5 }}></div>;
  }
}
export default StackBarHorizontal;
